import React, { PropsWithChildren } from "react";

import Footer from "./footer";
import Navigation from "./navigation";

const Container: React.FunctionComponent<
    PropsWithChildren<{
        noNavigation?: boolean;
        heroSource?: string;
        noHero?: boolean;
    }>
> = ({ children, noHero = false, heroSource, noNavigation = false }) => (
    <>
        <Main noHero={noHero}>{children}</Main>

        {!noNavigation && <Navigation heroSource={heroSource} noHero={noHero} hideOnStart={false} />}
        <Footer />
    </>
);

export default Container;

const Main: React.FunctionComponent<PropsWithChildren<{ noHero: boolean }>> = ({ children, noHero = false }) => (
    <main className="py-6 bg-white lg:py-24">{children}</main>
);

export { Container, Main };
