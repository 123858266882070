import React from "react";
import Title from "../components/title";
import Container from "../components/container";

const CheckoutPage: React.FunctionComponent = () => {

  const isBrowser = typeof window !== "undefined";
  return (
    <Container noHero>
      <div className="mx-auto mt-24 lg:mt-12 max-w-7xl">
        <Title
          title={"Bestellung erhalten"}
          subtitle={""}
          breadcrumb={"Nur noch wenige Schritte"}
        />
        <div className="relative grid grid-cols-1 mx-auto gap-x-16 max-w-7xl lg:px-8 xl:gap-x-20">
          <div className="flex flex-col items-center justify-center w-full">
            <p className="px-4 mx-auto pt-[40px] text-xl font-light leading-relaxed text-center text-black lg:px-0 max-w-7xl">
              Vielen Dank!
            </p>
            <p className="px-4 pt-4 mx-auto text-xl font-light leading-relaxed text-center text-black lg:px-0 max-w-7xl">
              Ihre Bestellung #{" "}
              {isBrowser &&
              !Number.isNaN(Number(window.location.href.split("=")[1]))
                ? window.location.href.split("=")[1]
                : ""}{" "}
              ist abgeschlossen und übermittelt.
            </p>
            <p className="px-4 mx-auto text-xl font-light leading-relaxed text-center text-black lg:px-0 max-w-7xl">
              Sie erhalten per E-Mail eine Bestätigung.
            </p>
            <p className="px-4 mx-auto text-xl font-light leading-relaxed text-center text-black lg:px-0 max-w-7xl">
              Bitte überprüfen Sie die Angaben.
            </p>
            <p className="px-4 mx-auto text-xl font-light leading-relaxed text-center text-black lg:px-0 max-w-7xl">
              Bei Fragen dürfen Sie mich gerne kontaktieren.
            </p>
            <p className="px-4 mx-auto text-xl font-light leading-relaxed text-center text-black pt-4 lg:px-0 max-w-7xl">
              Zu Ihren Diensten und mit genussvollen Grüssen
            </p>
            <p className="px-4 mx-auto text-xl font-light leading-relaxed text-center text-black lg:px-0 max-w-7xl">
              Ihr BrunchButler
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default CheckoutPage;
