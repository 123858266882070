import React from "react";
import Tie from "../images/tie.svg";

export default function Title({ breadcrumb, title, subtitle }: { breadcrumb?: string; title: string; subtitle?: string }) {

    return (
        <div className="px-4 text-center mb:pt-0">
            <Tie className="mx-auto mb-6 h-7" src="./tie.svg" alt="" />
            <h1 dangerouslySetInnerHTML={{__html: title}} className="mt-1 mb-8 font-serif text-4xl font-normal text-gray-900 sm:text-5xl sm:tracking-tight lg:text-5xl"></h1>
            <p dangerouslySetInnerHTML={{__html: subtitle}} className="mx-auto text-xl font-light leading-relaxed text-black max-w-7xl"></p>
        </div>
    );
}
